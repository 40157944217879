/* eslint-disable react-hooks/rules-of-hooks */
import '@/styles/globals.css';
import React from 'react';
import type { AppProps } from 'next/app';
import { store } from '../stores/store';
import { AnalyticsProvider } from 'reaxl-analytics';
import psxBuyerLandingAnalyticsHandler from '../analytics/psxBuyerLandingAnalyticsHandler';
import useDataLayer from '../analytics/useDataLayer';
import useDataIsland from '../analytics/useDataIsland';
import '../styles/atcui.scss';
import '../styles/index.scss';
import {
  Provider as FeatureFlagsContextProvider,
} from 'reaxl-features';
import { Provider as ReduxProvider } from 'react-redux';
import optimizelyClient from '../utilities/optimizelyClient';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import consumerId from '../utilities/myConsumerId';

const dataIsland = useDataIsland();
const dataLayer = useDataLayer();
const analyticsProps = {
  dataLayer: () => ({ ...dataLayer }),
  getDataIsland: () => ({ ...dataIsland }),
  value: {
    ...psxBuyerLandingAnalyticsHandler,
  },
  option: {
    isOptimizelyEdge: true,
    disableGTM: false,
    disableGTMEvents: false,
    disablePixall: false,
  },
};
export default function App({ Component, pageProps }: AppProps) {
  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={{ id: consumerId }}
    >
      <ReduxProvider store={store}>
        <AnalyticsProvider {...analyticsProps}>
          <FeatureFlagsContextProvider>
            <Component {...pageProps} />
          </FeatureFlagsContextProvider>
        </AnalyticsProvider>
      </ReduxProvider>
    </OptimizelyProvider>
  );
}
