/* eslint-disable arrow-body-style, no-unused-vars */
import {
  page,
  // Buyer Landing Hero
  shopPrivateSellerClick,
  psxSellMyCarClick,
  viewMyDashboardClick,
  // Shopping Steps
  psxVideoStart,
  psxVideoComplete,
  buyOnlineClick,
  psxBLPVehicleServiceContractsClick,
  psxBLPFinanceClick,
  psxBLPGapInsuranceClick,
  // Car Types
  atPSXStyleCategoryCTAClick,
  // Ensure Trust
  identityCheckClick,
  faqClick,
  // Popular Vehicles
  shopAllPrivateSellerCarsClick,
  atPrivateSellerCarouselNavigationClick, // previous or next.
  inventoryImpressions,
  inventoryClick,
  // Sell Your Car -> psxSellMyCarClick again
  // My Area
  privateSellerCarsInMyArea,
  privateSellerTrucksInMyArea,
  howToSellACarInStateClick,
  // Footer
  footerLinkClick,
  // Header
  carAlertsClick,
  inventorySave,
  headerGeneralEventHandler,
  // Popular vehicles and Tips
  psxPrivateSellerCarsClick,
  psxSellMyCarLinkClick,
  psxPreQualifyClick,
  psxWarrantyClick,
  psxEscrowClick,
  psxAutoGapInsuranceClick,
  psxVehicleProtectionClick,
  psxBuyerGuideClick,
  psxSellerGuideClick,
  psxFAQsClick,
} from 'reaxl-analytics';
import { atcBaseUrl } from '@/constants/config';
import { getCookie } from '../utilities/browserHelpers';
import { getBrowserZipcode } from '@/utilities/akamaiFunctions';

const getZipCode = () => {
  return getCookie('ATC_USER_ZIP') || getBrowserZipcode() || '';
};
const baseAtcData = {
  wspCode: 'Autotrader',
  dealerCode: 'autotradercox',
  fullUrl: atcBaseUrl + '/marketplace',
  zipCode: getZipCode(),
};
export default {
  page: (metadata: any, option: any) => {
    const { data } = metadata;
    return page({
      ...metadata,
      data: {
        ...data,
        friendlyPageName: 'PSX Buyer Landing Page',
        pageType: 'own',
        pageEvents: [],
      },
      state: {
        user: {
          location: {
            zip: getZipCode(),
          },
        },
      },
    }, option);
  },
  footerLinkClick: (metadata: any, option: any) => {
    return footerLinkClick({
      ...metadata,
    }, option);
  },
  carAlertsClick: (metadata: any, option: any) => {
    return carAlertsClick({
      ...metadata,
    }, option);
  },
  inventorySave: (metadata: any, option: any) => {
    return inventorySave({
      ...metadata,
    }, option);
  },
  headerGeneralEventHandler: (metadata: any, option: any) => {
    return headerGeneralEventHandler({
      ...metadata,
    }, option);
  },
  // Buyer Landing Hero
  shopPrivateSellerClick: (metadata: any, option: any) => {
    const { data } = metadata;
    return shopPrivateSellerClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  psxSellMyCarClick: (metadata: any, option: any) => {
    const { data } = metadata;
    return psxSellMyCarClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  viewMyDashboardClick: (metadata: any, option: any) => {
    const { data } = metadata;
    return viewMyDashboardClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  // Shopping Steps
  psxVideoStart: (metadata: any, option: any) => {
    return psxVideoStart({
      ...metadata,
    }, {
      ...option,
      isPsxBlp: true,
    });
  },
  psxVideoComplete: (metadata: any, option: any) => {
    const { data } = metadata;
    return psxVideoComplete({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, {
      ...option,
      isPsxBlp: true,
    });
  },
  buyOnlineClick: (metadata: any, option: any) => {
    return buyOnlineClick({
      ...metadata,
    }, option);
  },
  psxBLPVehicleServiceContractsClick: (metadata: any, option: any) => {
    return psxBLPVehicleServiceContractsClick({
      ...metadata,
    }, option);
  },
  psxBLPFinanceClick: (metadata: any, option: any) => {
    return psxBLPFinanceClick({
      ...metadata,
    }, option);
  },
  psxBLPGapInsuranceClick: (metadata: any, option: any) => {
    return psxBLPGapInsuranceClick({
      ...metadata,
    }, option);
  },
  // Car Types
  atPSXStyleCategoryCTAClick: (metadata: any, option: any) => {
    const { data } = metadata;
    return atPSXStyleCategoryCTAClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  // Ensure Trust
  identityCheckClick: (metadata: any, option: any) => {
    const { data } = metadata;
    return identityCheckClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  faqClick: (metadata: any, option: any) => {
    const { data } = metadata;
    return faqClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  // Popular Vehicles
  shopAllPrivateSellerCarsClick: (metadata: any, option: any) => {
    return shopAllPrivateSellerCarsClick({
      ...metadata,
    }, option);
  },
  inventoryImpressions: (metadata: any, option: any) => {
    return inventoryImpressions({
      ...metadata,
    }, option);
  },
  atPrivateSellerCarouselNavigationClick: (metadata: any, option: any) => {
    return atPrivateSellerCarouselNavigationClick({
      ...metadata,
    }, option);
  },
  inventoryClick: (metadata: any, option: any) => {
    const { data } = metadata;
    return inventoryClick({
      ...metadata,
      data: data.data,
      state: data.state,
    }, option);
  },
  // My Area
  privateSellerCarsInMyArea: (metadata: any, option: any) => {
    const { data } = metadata;
    return privateSellerCarsInMyArea({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  privateSellerTrucksInMyArea: (metadata: any, option: any) => {
    const { data } = metadata;
    return privateSellerTrucksInMyArea({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  howToSellACarInStateClick: (metadata: any, option: any) => {
    const { data } = metadata;
    return howToSellACarInStateClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  privateSellerCars: (metadata: any, option: any) => {
    const { data } = metadata;
    return psxPrivateSellerCarsClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  sellMyCars: (metadata: any, option: any) => {
    const { data } = metadata;
    return psxSellMyCarLinkClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  preQualify: (metadata: any, option: any) => {
    const { data } = metadata;
    return psxPreQualifyClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  warranty: (metadata: any, option: any) => {
    return psxWarrantyClick({
      ...metadata,
    }, option);
  },
  escrow: (metadata: any, option: any) => {
    return psxEscrowClick({
      ...metadata,
    }, option);
  },
  autoGAPInsurance: (metadata: any, option: any) => {
    const { data } = metadata;
    return psxAutoGapInsuranceClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  vehicleProtection: (metadata: any, option: any) => {
    const { data } = metadata;
    return psxVehicleProtectionClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  FAQs: (metadata: any, option: any) => {
    const { data } = metadata;
    return psxFAQsClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  buyerGuide: (metadata: any, option: any) => {
    const { data } = metadata;
    return psxBuyerGuideClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
  sellerGuide: (metadata: any, option: any) => {
    const { data } = metadata;
    return psxSellerGuideClick({
      ...metadata,
      data: {
        ...data,
        ...baseAtcData,
      },
    }, option);
  },
};
