import querystring from 'querystring';
import hasDom from 'has-dom';
// eslint-disable-next-line import/prefer-default-export
export function getPersonalizationClientEnv() {
  let env = 'qa';
  if (hasDom()) {
    const { location: { hostname, search } } = window;
    const isProd = ['www.autotrader.com', 'speedy.autotrader.com'].includes(hostname);
    if (isProd) {
      env = 'prod';
    } else {
      const query = querystring.parse(search.slice(1));
      if (query.useGigyaDev) {
        // useGigyaDev param is used for automation
        env = 'dev';
      }
    }
  }
  return { env };
}
