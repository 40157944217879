import { getLocalStorage, getCookie } from './browserHelpers';

export function getBrowserZipcode() {
  const akamaiLocationData = getCookie('x-kbb-aka-data');
  let zipCode = '';
  if (akamaiLocationData) {
    const decodedLocationData = decodeURI(akamaiLocationData);
    const akamaiSplit = decodedLocationData.split('|');
    const akamaiZip = akamaiSplit[2];
    if (akamaiZip && akamaiZip.length === 5) {
      zipCode = akamaiZip;
    }
  } else {
    const peDataJSON = getLocalStorage('pe_data');
    const peData = peDataJSON ? JSON.parse(peDataJSON) : '';
    if (peData) {
      const pePrimaryZip = peData?.personalization?.primaryMarketZip;
      if (pePrimaryZip) {
        zipCode = pePrimaryZip;
      }
    }
  }
  return zipCode;
}
const defaultZip = getBrowserZipcode();
export default defaultZip;
