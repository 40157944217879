import { userDuckCreator } from 'axl-ducks';
import { getPersonalizationClientEnv } from './duckUtils';
import inventoryDuck from './inventoryDuck';
import savedInventoryDuck from './savedInventoryDuck';

const userDuck = userDuckCreator({
  inventoryDuck,
  savedInventoryDuck,
  ...getPersonalizationClientEnv(),
});
export default userDuck;
