import {
  createInstance,
  setLogger,
} from '@optimizely/react-sdk';
import { optimizelySdkKey } from '../constants/config';

const optimizelyClient = createInstance({
  sdkKey: optimizelySdkKey,
  eventBatchSize: 10,
  eventFlushInterval: 1000,
  datafileOptions: {
    autoUpdate: true,
    updateInterval: 30000, // 30 seconds in milliseconds
  },
});
setLogger(null);
export default optimizelyClient;
