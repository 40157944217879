const isProd = process.env.CONFIG === 'prod';
const atcBaseUrl = isProd ? 'https://www.autotrader.com' : 'https://test.autotrader.com';
const kbbAndAtcLogoImgUrl = 'combinedlanding-logos.webp';
const psxTransactionBaseUrl = isProd ? 'https://rp-ps-scuderia.awscmsprivateseller.autotrader.com' : `${atcBaseUrl}/marketplace`;
const popularListingsPath = '/api/listings/search/recentlistings?status=active&page_size=15&has_images=true';
const staticUrl = process.env.STATIC_URL && '/';
const optimizelySdkKey = isProd ? '7Cv1BBWA6VS6q8wsJyeE9' : 'MX5P86GRKya36dYU2Z37W';
const regionShort = process.env.REGION_SHORT || 'use1';
const psxStaticUtilUrl = '/psx-buy/psx-static-util.js';
export {
  isProd,
  atcBaseUrl,
  kbbAndAtcLogoImgUrl,
  staticUrl,
  psxTransactionBaseUrl,
  popularListingsPath,
  optimizelySdkKey,
  regionShort,
  psxStaticUtilUrl,
};
