export function getCookie(name) {
  function escape(s) {
  // eslint-disable-next-line
  const escapeReturn = s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
    return escapeReturn;
  }
  let match;
  if (typeof window !== 'undefined') {
  // eslint-disable-next-line
    match = window?.document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
  }
  return match ? match[1] : null;
}
export function getLocalStorage(name) {
  let match;
  if (typeof window !== 'undefined') {
    match = window?.localStorage.getItem(name);
  }
  return match?.toString('ascii');
}
export function getSignedInStatus() {
  let status = false;
  if (typeof window !== 'undefined') {
    status = window?.isUserSignedIn || window?.localStorage.getItem('isUserSignedIn');
  }
  return status;
}
