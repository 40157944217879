/* eslint-disable arrow-body-style */
import _get from 'lodash/get';

export const getCommaSeparatedList = (items, prop, array, target, rank = null) => {
  const list = [];
  if (items && items[prop] && Object.prototype.hasOwnProperty.call(items[prop], array)) {
    if (rank) {
      items[prop][array].forEach((element) => {
        if (element.rank === rank) {
          list.push(element[target].desc);
        }
      });
    } else {
      items[prop][array].forEach((element) => {
        list.push(element[target].desc);
      });
    }
  }
  return list.join();
};
export const translateDataLayerConsumerAdTargetsWithoutCAMPBias = (data) => {
  return {
    intent: _get(data, 'targetIntent', 'INCONCLUSIVE'),
    preferredMakes: getCommaSeparatedList(data, 'targetMakes', 'makes', 'make') || 'INCONCLUSIVE',
    preferredModel: getCommaSeparatedList(data, 'targetModels', 'models', 'model', 1) || 'INCONCLUSIVE',
    secondPreferredModel: getCommaSeparatedList(data, 'targetModels', 'models', 'model', 2) || 'INCONCLUSIVE',
    subCategory: getCommaSeparatedList(data, 'targetSegments', 'segments', 'segment') || 'INCONCLUSIVE',
    tradeInMake: _get(data, 'targetTradeInVehicle.make.desc', 'INCONCLUSIVE'),
    tradeInModel: _get(data, 'targetTradeInVehicle.model.desc', 'INCONCLUSIVE'),
  };
};
export const translateDataLayerConsumerAdTargets = (consumerAdTargets, preferences) => {
  return {
    consumerAdTargetInsightsAvailable: _get(consumerAdTargets, 'targetInsightsAvailable', 'INCONCLUSIVE').toString(),
    consumerAdTargetIntent: _get(consumerAdTargets, 'targetIntent', 'INCONCLUSIVE'),
    consumerAdTargetMakes: getCommaSeparatedList(consumerAdTargets, 'targetMakes', 'makes', 'make') || 'INCONCLUSIVE',
    consumerAdTargetModel: getCommaSeparatedList(consumerAdTargets, 'targetModels', 'models', 'model', 2) || 'INCONCLUSIVE',
    consumerAdTargetModels: getCommaSeparatedList(consumerAdTargets, 'targetModels', 'models', 'model') || 'INCONCLUSIVE',
    consumerAdTargetSubCategory: getCommaSeparatedList(consumerAdTargets, 'targetSegments', 'segments', 'segment') || 'INCONCLUSIVE',
    consumerAdTargetTradinMake: _get(consumerAdTargets, 'targetTradeInVehicle.make.desc', 'INCONCLUSIVE'),
    consumerAdTargetTradinModel: _get(consumerAdTargets, 'targetTradeInVehicle.model.desc', 'INCONCLUSIVE'),
    consumerCampaignPreferencesAddedByRule: preferences?.topRankedVehicle?.addedByRule,
  };
};
export const translateDataLayerConsumerInsights = (data) => {
  return {
    ...data,
    apiTrackingId: _get(data, 'trackingId'),
    fixedOps: _get(data, 'fixedOps')?.toString(),
    drActivity: _get(data, 'drActivity')?.toString(),
  };
};
export const translateDataLayerConsumerMarketingSegments = (data) => {
  return {
    demographics: _get(data, 'demographics', 'INCONCLUSIVE'),
    lifeEvents: _get(data, 'lifeEvents', 'INCONCLUSIVE'),
    clusters: _get(data, 'clusters', 'INCONCLUSIVE'),
  };
};
