import { userDuck } from '../ducks';
import { pageInstanceId } from 'atc-js';
import { isProd } from '@/constants/config';
import useDataIsland from './useDataIsland';
import { getBrowserZipcode } from '../utilities/akamaiFunctions';
import { getCookie, getSignedInStatus } from '../utilities/browserHelpers';
import { getLocalStorage } from '../utilities/browserHelpers';
import { translateDataLayerConsumerAdTargets, translateDataLayerConsumerInsights, translateDataLayerConsumerAdTargetsWithoutCAMPBias, translateDataLayerConsumerMarketingSegments } from './translatePersonalizationData';

export default function useDataLayer() {
  const zip = getCookie('ATC_USER_ZIP') || getBrowserZipcode();
  const pxaId = getCookie('pxa_id');
  const pxaIdBackup = getCookie('pxa_id_backup');
  const abc = getCookie('abc');
  const pxaRealId = getCookie('pxa_realid');
  const pixallAbc = getCookie('pixall_abc');
  const dataIsland = useDataIsland();
  const pgStId = dataIsland?.page?.BIRF?.pg_inst;
  const peDataJSON = getLocalStorage('pe_data');
  const peData = peDataJSON ? JSON.parse(peDataJSON) : '';
  const signInStatus = getSignedInStatus() ? '1' : '0';
  const consumerInsights = translateDataLayerConsumerInsights(peData?.personalization);
  const consumerAdTargets = translateDataLayerConsumerAdTargets(peData?.consumerAdTargets, peData?.preferences);
  const consumerAdTargetsWithoutCAMPBias = translateDataLayerConsumerAdTargetsWithoutCAMPBias(peData?.consumerAdTargetsWithoutCAMPBias);
  const consumerMarketingSegments = translateDataLayerConsumerMarketingSegments(peData?.consumerMarketingSegments);
  return {
    catalog: 'atc',
    common: {
      application: {
        name: 'listings',
      },
      user: {
        isLoggedIn: signInStatus,
      },
    },
    consumerInsights: consumerInsights || null,
    consumerAdTargets: consumerAdTargets || null,
    consumerAdTargetsWithoutCAMPBias: consumerAdTargetsWithoutCAMPBias || null,
    consumerMarketingSegments: consumerMarketingSegments || null,
    application: {
      businessUnit: 'autotrader',
      name: 'web',
      environment: isProd ? 'production' : 'qa',
      version: '1.0.0',
    },
    page: {
      siteSection: 'own',
      pageType: 'slp_psx',
      detailPageName: 'slp_psx_buy', // if other pages are added, change this dynamically
      pg: 'syc_psx_buy',
      pixallABC: abc || pixallAbc || pxaRealId,
      pixallId: pxaId || pxaIdBackup,
      pixallPageInstanceId: pgStId || pageInstanceId.getRandomId(),
    },
    user: {
      isLoggedIn: signInStatus,
      zipCode: zip,
    },
    userDuck,
  };
}
