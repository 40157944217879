import { getLocalStorage } from '@/utilities/browserHelpers';
import { configureStore, createSlice } from '@reduxjs/toolkit';

const data = getLocalStorage('ATC_USER_PROFILE');
const isSignedIn = getLocalStorage('isUserSignedIn');
const userData = getLocalStorage('userProfile');
let dataJson = null;
let loggedInStatus;
let userJson = null;
if (typeof userData !== 'undefined') {
  userJson = JSON.parse(userData);
}
if (typeof data !== 'undefined') {
  dataJson = JSON.parse(data);
}
if (typeof isSignedIn !== 'undefined') {
  loggedInStatus = JSON.parse(isSignedIn);
}
const buyerLandingPageSlice = createSlice({
  name: 'buyerLandingPage',
  initialState: {
    savedVehicles: [],
    savedVehicleCount: 0,
    showQuickSaveList: {},
    isLoggedIn: loggedInStatus,
    user: {
      showUserState: true,
      profile: {
        firstName: userJson?.given_name ? userJson.given_name : null,
        lastName: '',
        email: '',
        phone: '',
      },
      bookmarks: {
        subscribe: {},
        searchIds: dataJson?.searchIds ? dataJson.searchIds : [],
        search: {},
        emailAlerts: [],
        inventoryBookmarks: {},
        searchBookmarks: {},
      },
      location: {
        city: '',
        state: '',
        zip: '',
      },
      psxNotificationsCount: 0,
    },
    auth: {
      showUserState: true,
      showRegisterUser: false,
      isLoggedIn: loggedInStatus,
      unverifiedEmail: '',
      isVerificationEmailResent: false,
      loginProviderName: '',
      authState: {
        showModal: false,
        message: {},
        screen: 'signIn',
        screenOptionKey: '',
        heading: 'Sign In',
        showLoginSuccess: false,
        userExists: false,
        alert: null,
        currentUser: {},
      },
    },
    savedInventory: {
      inventory: dataJson ? dataJson.carIds : [],
    },
    payments: {
      tradeIn: {},
      trade: {
        hasNewTradeIn: false,
        valuationHistory: {},
      },
      creditAppDeals: [],
    },
    hasNewTradeIn: false,
  },
  reducers: {
    incrementSavedVehicleCount: (state: any) => {
      state.savedVehicleCount += 1;
    },
  },
});
export const store = configureStore({
  reducer: buyerLandingPageSlice.reducer,
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
