import { pageInstanceId } from 'atc-js';

export default function useDataIsland() {
  return {
    page: {
      BIRF: {
        pg: 'syc_psx_buy', // pg or pg id.
        pg_inst: pageInstanceId.getRandomId(), // pg instance
        pg_id: 'slp_psx_buy',
      },
      vehicle: {
        car_type: 'used',
      },
    },
  };
}
